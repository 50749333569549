import React from 'react'
import { map, includes, filter } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Content, Button, Grid, SearchTable, TableHeader, Tab } from '../../common'
import { clearString } from '../../common/utils'
import { loadReclamation, contactCompany, editReclamation } from '../../actions/reclamations.actions'

const ReclamationByCityTab = ({
  reclamationsByCity,
  currentCityId,
  isActiveTab,
  setSearchQueries,
  prevSearchQueries,
  checkedItems,
  setCheckedItems
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { residences } = useSelector(state => state.residences)
  const {
    categories,
    statuses
  } = useSelector(state => state.reclamations)

  const categoriesItems = categories.map(n => ({ id: n.label, name: n.label })) || []
  const statusesItems = statuses.map(s => ({
    id: s.label,
    name: s.label,
  }))

  const actionsRenderer = ({ row }) => {
    return (
      <div className='table_buttons'>
        <Button
          className='icon_button detail_button'
          onClick={() => {
            dispatch(loadReclamation(row.id))
            history.push(`/admin/reclamation/edit/${row.id}`)
          }}>
          Detail
				</Button>
        <Button
          className='icon_button email_button'
          onClick={() => {
            dispatch(contactCompany(row.id))
          }}
          isDisabled={clearString(row.status) === clearString('Předána k odstranění')}>
          K odstranění
				</Button>
        <Button
          className='icon_button archive_button'
          onClick={() => {
            dispatch(editReclamation({ ...row, archived: true }))
          }}>
          Archivovat
				</Button>
      </div>
    )
  }

  const statusRenderer = ({ row }) => {
    let className = ''
    const status = clearString(row.status)

    if (
      status === clearString('Zkontrolovaná') ||
      status === clearString('Předána k odstranění') ||
      status === clearString('Odstraněna')
    ) {
      className = 'state state--progress'
    } else if (status === clearString('Vyřešena')) {
      className = 'state state--completed'
    } else if (status === clearString('Neuznaná')) {
      className = 'state state--notcompleted'
    } else {
      className = 'state state--new'
    }

    return <span className={className}>{row.status}</span>
  }

  const setStateCheckedItems = (id) => {
    if (id === 'all') {
      if (checkedItems.length === reclamationsByCity.length) {
        setCheckedItems([])
      } else {
        setCheckedItems(map(reclamationsByCity, 'id'))
      }

      return;
    }

    const isChecked = includes(checkedItems, id)

    if (isChecked) {
      setCheckedItems(filter(checkedItems, (item) => item !== id))
    } else {
      setCheckedItems([...checkedItems, id])
    }
  }


  const residenceNamesItems = residences.reduce((acc, item) => {
    if (currentCityId === 'all' || (item.city && currentCityId === clearString(item.city))) {
      return [
        ...acc,
        { id: item.name, name: item.name }
      ]
    } else {
      return acc
    }
  }, [])

  const filterableColumns = [
    { dataId: 'id', label: 'ID' },
    {
      dataId: 'rezidence',
      label: 'Rezidence',
      type: 'dropdown',
      options: [{ name: '-' }, ...residenceNamesItems],
    },
    { dataId: 'byt', label: 'Číslo bytu' },
    { dataId: 'datumPodani', label: 'Datum podání', type: 'date' },
    {
      dataId: 'kategorie',
      label: 'Kategorie závady',
      type: 'dropdown',
      options: [{ name: '-' }, ...categoriesItems],
    },
    {
      dataId: 'status',
      label: 'Stav',
      type: 'dropdown',
      options: [{ name: '-' }, ...statusesItems],
    },
    { label: '' },
  ]

  return (
    <Tab key={currentCityId} id={currentCityId} isActive={isActiveTab}>
      <Grid>
        <Box>
          <Content>
            <SearchTable
              className='table table-hover thin_rows'
              rows={reclamationsByCity}
              filterableColumns={filterableColumns}
              onCheckedItem={setStateCheckedItems}
              checkedItems={checkedItems}
              setPrevSearchQueries={setSearchQueries}
              prevSearchQueries={prevSearchQueries}>
              <TableHeader label='ID' dataId='id' />
              <TableHeader label='Rezidence' dataId='rezidence' />
              {currentCityId === 'bafire' && <TableHeader label='Adresa' dataId='bafire_address' />}
              <TableHeader label='Číslo bytu' dataId='byt' />
              <TableHeader label='Datum podání' dataId='datumPodani' />
              <TableHeader label='Kategorie závady' dataId='kategorie' />
              <TableHeader label='Stav' dataId='status' cellRenderer={statusRenderer} />
              <TableHeader label='Akce' dataId='akce' cellRenderer={actionsRenderer} />
            </SearchTable>
          </Content>
        </Box>
      </Grid>
    </Tab>
  );
}

export default ReclamationByCityTab
